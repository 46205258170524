export enum LocalStorageKey {
  GlobalSettings = 'globalSettings',
  PomodoroSettings = 'pomodoroSettings',
  IsPreviewNotificationDismissed = 'previewNotification',
  DashboardLayout = 'dashboardLayout',
  WidgetPanelSize = 'widgetPanelSize',
  CoffeeWeight = 'coffeeWeight',
  DiabloWorldTier = 'diabloWorldTier',
  DiabloPlayerLevel = 'diabloPlayerLevel',
  DiabloSigilTier = 'diabloSigilTier',
  DiabloMonsterLevelOffset = 'diabloMonsterLevelOffset',
  ContentDensity = 'contentDensity',
  VisualMode = 'visualMode',
  IsNewVisitor = 'isNewVisitor',
  BjWinnings = 'bjWinnings',
  BjLowWater = 'bjLowWater',
  BjHighWater = 'bjHighWater',
  BjPlayerWins = 'bjPlayerWins',
  BjDealerWins = 'bjDealerWins',
  BjHandsPlayed = 'bjHandsPlayed',
  OwGames = 'owGames',
}
